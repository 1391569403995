<template>
  <div class="num-to-Ground">
    <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/NumToGround1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="color: white; font-weight: normal !important">服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="animation-delay: 1s; text-indent: 2em"
        >
          管理体系建设最终要有效落地，否则就是空中楼阁。针对“体系建立容易落地难”、“检查工作流于形式”的老大难问题，景翔开发了不同于一般的软件执行系统、更不同于OA系统的云体系平台，是国内首创的数字化落地系统，其覆盖了企业的整套管理体系，是落地自动化的一个平台，轻易解决了体系落地这一难题!
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p
            style="
              text-align: start;
              color: white;
              font-weight: normal !important;
            "
          >
            服务内容：
          </p>
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s; white-space: pre-wrap"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list3"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul ul4">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s; white-space: pre-wrap"
            v-for="(item, index) in serveList.list4"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number2">
      <img src="@assets/images/NumToGround2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p>了解我们的产品</p>
          <p></p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeIn'">
          <img
            class="animate__animated"
            v-scroll="'animate__fadeIn'"
            src="@assets/images/NumToGround3.png"
            alt=""
          />
          <div class="text">
            <p class="p1">传统体系落地</p>
            <div class="pbox1">
              <p>体系文件编写</p>
              <p>体系运行</p>
              <p>体系内审</p>
            </div>
            <div class="pbox2">
              <p>数字化文件编写</p>
              <p>题库体系建立</p>
              <p>题库学习考试</p>
              <p>云体系平台落地</p>
            </div>
            <p class="p2">景翔体系落地</p>
          </div>
        </div>
      </div>
    </div>
    <div class="number3">
      <img src="../../../assets/images/NumToGround4.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>数字化落地的特点</p>
          <p></p>
        </div>
        <div class="fivebox">
          <div
            class="animate__animated"
            v-scroll="'animate__rollIn'"
            v-for="(item, index) in featureList"
            :key="index"
          >
            <img :src="item.img" />
            <p class="p1 Rem0_24">
              {{ item.p1 }}
            </p>
            <p class="p2 Rem0_20">
              {{ item.p2 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number4">
      <div class="contain">
        <div class="Rem0_4">
          <p>景翔云体系落地平台</p>
          <p></p>
        </div>
        <div class="imgbox">
          <div class="animate__animated" v-scroll="'animate__fadeInUpBig'">
            <p class="p1 Rem0_30">日常工作检查与内审合二为一</p>
            <p class="T2"></p>
            <p class="p2 Rem0_22">
              景翔云体系平台的其中一个模块是体系数字化落地，是内部审核与日常工作检查合二为一的，其功能确保了企业管理体系落地无死角。
            </p>
            <span class="CustomButton" @click="$router.push('./trial-use')"
              >免费使用</span
            >
          </div>
          <img src="../../../assets/images/NumToGround5.png" alt="" />
        </div>
      </div>
    </div>
    <div class="number5">
      <img src="../../../assets/images/NumToGround9.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>体系落地系统+移动端</p>
          <p class="T1" style="margin-left: 0.04rem"></p>
        </div>
        <div class="box-img">
          <div>
            <img src="../../../assets/images/NumToGround6.png" alt="" />
          </div>
          <div>
            <img src="../../../assets/images/NumToGround7.png" alt="" />
            <p class="absolute-p Rem0_24">
              移动端看板：<br />
              将监管和作业搬到移动端上，成为“监管看板”和“作业看板”。
            </p>
          </div>
          <img
            class="absolute-img"
            src="../../../assets/images/NumToGround8.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="number6">
      <img src="../../../assets/images/NumToGround10.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>系统软件/云平台支撑下的体系落地循环</p>
          <p class="T1" style="margin-left: 0.04rem"></p>
        </div>
        <div class="icon-box">
          <div class="top">
            <div>
              <img src="../../../assets/images/NumToGround-l1.png" alt="" />
              <span class="Rem0_25">基础数据输入建模</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-l2.png" alt="" />
              <span class="Rem0_25">终端工作任务发起</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-l3.png" alt="" />
              <span class="Rem0_25">后台工作任务分派</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-l4.png" alt="" />
              <span class="Rem0_25">任务处置即时反馈</span>
            </div>
          </div>
          <div class="center">
            <img
              class="img-c2"
              src="../../../assets/images/NumToGround-c2.png"
              alt=""
            />
            <img
              class="img-c1"
              src="../../../assets/images/NumToGround-c1.png"
              alt=""
            />
            <p class="Rem0_25">
              绩效驱动软件通过手机APP软件建立人与人、以及人与PC端连接，工作人员通过手机端即可完成任务发起、分配、查询、预警、反馈、评价、数据统计分析等工作内容；完美解决辅助部门例外事（临时性工作）的工作进度管理。
            </p>
            <img
              class="img-c3"
              src="../../../assets/images/NumToGround-c3.png"
              alt=""
            />
          </div>
          <div class="bottom">
            <div>
              <img src="../../../assets/images/NumToGround-r1.png" alt="" />
              <span class="Rem0_25">基础数据输入建模</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-r2.png" alt="" />
              <span class="Rem0_25">终端工作任务发起</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-r3.png" alt="" />
              <span class="Rem0_25">后台工作任务分派</span>
            </div>
            <div>
              <img src="../../../assets/images/NumToGround-r4.png" alt="" />
              <span class="Rem0_25">任务处置即时反馈</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="number7">
      <div class="Rem0_4 animate__animated" v-scroll="'animate__flipInY'">
        <p style="color: #4375c8">云体系平台界面</p>
        <p class="T2"></p>
      </div>
      <div class="contain">
        <div class="tab animate__animated" v-scroll="'animate__fadeIn'">
          <p
            v-for="(item, index) in carouselDiv.tab"
            :key="index"
            @click="clickCarouselTab(index)"
            :class="{ carouselActive: index == carouseltabIndex }"
            class="Rem0_26"
          >
            {{ item }}
          </p>
        </div>

        <div class="carousel-img">
          <el-carousel
            class="animate__animated"
            v-scroll="'animate__fadeInDownBig'"
            trigger="click"
            height="6rem"
            ref="carouse"
            @change="changeCarousel"
          >
            <el-carousel-item
              v-for="(item, index) in carouselDiv.content"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </el-carousel-item>
          </el-carousel>
          <p class="Rem0_28">
            {{ consultItem.p1
            }}<span class="Rem0_21">{{ consultItem.p2 }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      serveList: {
        list1: [
          "建立体系树",
          "构建工作标准",
          "文件连接颗粒度细化",
          "建立标准题库",
          "建立作业文件题库",
        ],
        list2: [
          "将作业文件还原各\n岗位内容",
          "形成管理层监控看板",
          "形成操作层工作看板",
          "所有表单低代码设计",
          "建立学习中心",
        ],
        list3: [
          "建立岗位应知应会",
          "建立员工业务测试题",
          "体系落地检查",
          "现场7S检查",
          "现场安全检查",
        ],
        list4: [
          "协助或委托管理体系审核（如ISO9001、ISO14001、ISO45001、\n1IATF16949、VDA6.3等的审核）",
          "潜在供应商审核审核、\n关键供应商审核",
          "质量管理体系成熟度\n评价",
        ],
      },
      featureList: [
        {
          img: require("@assets/images/a.png"),
          p1: "检查自动化",
          p2: "有专门的落地平台支持，可自动生成检查计划（包括内审计划）、自动生成检查表、自动跟进检查发现的问题",
          index: 1,
        },
        {
          img: require("@assets/images/b.png"),
          p1: "学习中心",
          p2: "系统拥有“学习中心”功能，可以根据企业文件生成“应知应会”，并随时自动抽题检查员工的业务熟悉程度，为体系落地奠定基础",
          index: 1,
        },
        {
          img: require("@assets/images/c.png"),
          p1: "题库体系",
          p2: "景翔首创了“题库体系”，可将整套体系文件转化为各类型的检查清单，大幅减少了工作检查的工作量，使检查频次（包括内审频次）自然增加",
          index: 1,
        },
        {
          img: require("@assets/images/d.png"),
          p1: "软件赋能",
          p2: "由于落地软件的自动化和赋能功能，使检查工作变得简单，大幅降低了工作检查人员（包括内审人员）的门槛和成本，且效果更好",
          index: 1,
        },
        {
          img: require("@assets/images/e.png"),
          p1: "灵活的检查",
          p2: "可实现远程检查、交叉检查、突击检查、随机检查等，并且后台有大数据的支持，大大提升了检查工作的有效性，大幅减少监管行为",
          index: 1,
        },
      ],
      consultItem: {},
      carouseltabIndex: null,
      carouselDiv: {
        tab: ["平台首页", "审核计划", "审核检查表", "学习中心"],
        content: [
          {
            img: require("@assets/images/SystemToGround4.png"),
            p1: "记录均可查询",
          },
          {
            img: require("@assets/images/NumToGround11.png"),
            p1: "学习界面和业务测评界面",
          },
          {
            img: require("@assets/images/NumToGround12.png"),
            p1: "一键生成审核检查表：",
            p2: "一键生成审核检查表：由于有现成的检查题库，审核时可一键生成当下的检查表",
          },

          {
            img: require("@assets/images/NumToGround13.png"),
            p1: "学习界面和业务测评界面",
          },
        ],
      },
    };
  },
  mounted() {
    this.changeCarousel(0);
  },
  methods: {
    clickCarouselTab(index) {
      this.carouseltabIndex = index;
      this.$refs.carouse.setActiveItem(index);
    },
    changeCarousel(index) {
      this.carouseltabIndex = index;
      this.consultItem = this.carouselDiv.content[index];
    },
  },
};
</script>

<style lang="less" scoped>
.num-to-Ground {
  .number1 {
    position: relative;
    .contain {
      position: absolute;
      width: 80%;
      margin: 0 10%;
      color: white;
      text-align: start;
      top: 0;
      .Rem0_4:nth-of-type(1) {
        margin: 4% 0 3% 0;
        display: flex;
        justify-content: center;
      }
      .Rem0_4:nth-of-type(2) {
        margin: 2.5% 0 0.5% 0 !important;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 18px;
          background: linear-gradient(to right, #555974, #5d616f);
          cursor: default;
          height: 1rem;
          line-height: 0.3rem !important;
        }
      }
      .ul4 {
        span:nth-of-type(1) {
          flex: 2.95;
        }
        span:nth-of-type(2) {
          flex: 1.2;
        }
      }
    }
  }
  .number2 {
    position: relative;
    height: 100vh;
    > img:nth-of-type(1) {
      height: 100vh;
    }
    img {
      position: absolute;
    }
    .contain {
      position: absolute;
      top: 0;
      z-index: 9999;
      color: white;
      text-align: center;
      width: 100%;
      .Rem0_4 {
        color: #4375c8;
        padding: 5% 0;
        p:nth-of-type(2) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      div:nth-of-type(2) {
        display: flex;
        width: 100%;
        justify-content: center;
        img {
          width: 9.5rem;
        }
        .text {
          position: relative;
          width: 9.3rem;
          height: 4.9rem;
          p {
            position: absolute;
            font-size: 0.3rem;
            display: inline-block;
            cursor: default;
          }
          .p1 {
            left: 3.5%;
            top: 33%;
            writing-mode: vertical-rl;
            letter-spacing: 15px;
          }
          .p2 {
            writing-mode: vertical-rl;
            font-size: 0.36rem !important;
            letter-spacing: 15px;
            right: 4.5%;
            top: 25%;
          }
          .pbox1 {
            color: #3084c1;

            p:hover {
              font-weight: bold;
              color: #fd7a24;
            }
            p:nth-of-type(1) {
              left: 17%;
              top: 48.5%;
            }
            p:nth-of-type(2) {
              left: 17%;
              top: 68.5%;
            }
            p:nth-of-type(3) {
              left: 17%;
              top: 87.5%;
            }
          }
          .pbox2 {
            p {
              background: #f7f0f0;
              width: 2.85rem;
              border-radius: 50px 0 0 50px;
              padding: 0.2rem 0 0.2rem 0.5rem;
            }
            p:hover {
              font-weight: bold;
              color: #2a8fd7;
              background: #c9e3f6;
            }
            color: #fd7a24;
            p:nth-of-type(1) {
              right: 13%;
              top: 21%;
            }
            p:nth-of-type(2) {
              right: 13%;
              top: 42%;
            }
            p:nth-of-type(3) {
              right: 13%;
              top: 63%;
            }
            p:nth-of-type(4) {
              right: 13%;
              top: 84%;
            }
          }
        }
      }
    }
  }
  .number3 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      width: 80%;
      margin: 0 10%;
      text-align: center;
      top: 0;
      .Rem0_4 {
        margin-top: 6%;
        color: #4375c8;
        p:nth-of-type(2) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .fivebox {
      display: flex;
      justify-content: space-between;
      margin-top: 8%;
      div:hover {
        background: #4375c8;
        .p1 {
          color: white !important;
          transform: scale(1.25);
        }
        .p2 {
          color: white !important;
        }
      }
      div {
        flex: 1;
        text-align: center;
        padding: 35px 20px 15px 20px;
        border-radius: 15px;
        margin: 1%;
        background: #f6f6f6;
        img {
          width: 0.65rem;
          height: 0.65rem;
        }
        .p1 {
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
          color: #4375c8;
        }
        .p2 {
          line-height: 0.35rem !important;
          text-align: start;
          color: #333333;
        }
      }
    }
  }
  .number4 {
    .contain {
      width: 70%;
      margin: 5% 15% 0 15%;
      text-align: center;
      padding-bottom: 6%;
      .Rem0_4 {
        margin: 4% 0;
        color: #4375c8;
        p:nth-of-type(2) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .imgbox {
      display: flex;
      justify-content: center;
      margin-top: 10%;
      position: relative;
      > img {
        width: 70%;
        object-fit: contain;
      }
      > div {
        position: relative;
        margin-right: 2%;
        text-align: start;
        width: 24%;
        p:nth-of-type(1) {
          color: #4375c8;
          font-weight: bold;
        }
        p:nth-of-type(2) {
          line-height: 0.4rem !important;
          margin-top: 5%;
        }
        .lineimg {
          width: 0.6rem !important;
          height: 0.05rem;
        }
      }
      .CustomButton {
        position: absolute;
        bottom: 18%;
      }
    }
  }
  .number5 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      width: 70%;
      margin: 0 15%;
      top: 0;
      .Rem0_4 {
        margin: 10% 0 6% 0;
        color: white;
        p:nth-of-type(2) {
          background: white !important;
        }
      }
      .box-img {
        > div:nth-of-type(1) {
          padding: 0.7rem 0;
          margin-right: 1.5%;
          img {
            width: 92%;
          }
        }
        > div:nth-of-type(2) {
          padding: 0.3rem 0;
          overflow: hidden;
          margin-left: 1.5%;
          img {
            width: 70%;
          }
          p {
            position: absolute;
            bottom: 0;
            text-align: start;
            font-weight: bold;
            line-height: 0.38rem !important;
            width: 92%;
            padding: 3% 4%;
            color: white;
            background: #42649c;
          }
        }
        display: flex;
        > div {
          position: relative;
          background: white;
          text-align: center;
          border-radius: 10px;
          width: 50%;
        }
        .absolute-img {
          position: absolute;
          left: 50%;
          bottom: 22%;
          width: 1.1rem;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .number6 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      width: 70%;
      margin: 0 15%;
      top: 0;
      .Rem0_4 {
        color: white;
        margin-top: 12%;
      }
      .icon-box {
        width: 92%;
        margin: 16% 6% 0 6%;
        position: relative;
        img {
          width: 0.9rem;
          transform: rotate(0deg);
        }
        .top {
          z-index: 9999;
          margin-top: -6%;
          span:before {
            position: absolute;
            content: "";
            width: 0.25rem;
            height: 0.25rem;
            border: 3px solid #294f91;
            background: white;
            border-radius: 50%;
            bottom: -44%;
            left: -2.5%;
            z-index: 9999;
          }
        }
        .bottom {
          margin-top: 6%;
          span:before {
            position: absolute;
            content: "";
            width: 0.25rem;
            height: 0.25rem;
            border: 3px solid #294f91;
            background: white;
            border-radius: 50%;
            bottom: 110%;
            left: -2.5%;
            z-index: 9999;
          }
        }
        .top,
        .bottom {
          position: absolute;
          display: flex;
          left: 45%;
          transform: translate(-50%, -50%);

          div {
            display: flex;
            align-items: center;
            margin: 0 3%;
            position: relative;
            span {
              width: 1.2rem;
              padding-left: 0.13rem;
              line-height: 0.4rem !important;
              font-weight: bold;
              color: white;
              cursor: default;
            }
          }
          div:hover {
            span {
              font-size: 0.3rem;
            }
            span:before {
              border: 3px solid white;
              background: #294f91;
            }
            img {
              transform: rotate(360deg);
              transition: transform 0.5s ease;
            }
          }
        }
        .center {
          background: white;
          position: relative;
          display: flex;
          padding: 0.4rem;
          align-items: center;
          border-radius: 20px;
          p {
            line-height: 0.4rem !important;
            padding-left: 0.3rem;
            color: #4472c4;
          }
          .img-c1 {
            color: #4472c4;
          }
          .img-c2,
          .img-c3 {
            width: 0.5rem;
            position: absolute;
          }
          .img-c2 {
            left: -0.25rem;
          }
          .img-c3 {
            right: -0.25rem;
          }
        }
      }
    }
  }
  .number7 {
    background: white;
    height: 100vh;
    .Rem0_4 {
      padding: 2% 0 4% 15%;
    }
    .contain {
      width: 70%;
      margin: 0 15%;
      display: flex;
      justify-content: space-between;
      .tab {
        border-radius: 20px;
        width: 18%;
        p {
          width: 100%;
          text-align: center;
          margin: 12% 0;
          height: 0.6rem;
          line-height: 0.6rem;
          color: #666666;
          background: #f4f6fa;
          border-radius: 5px;
          cursor: pointer;
        }
        .carouselActive {
          color: white !important;
          background: #255ca2;
          font-weight: bold;
        }
      }
      .carousel-img {
        width: 78%;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          margin-top: 1%;
        }
        span {
          color: #757474;
        }
      }
    }
  }
}
</style>